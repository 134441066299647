import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { GsidSsoService } from './gsid-sso.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    private readonly gsidSsoService: GsidSsoService,
    @Inject(PLATFORM_ID) private platformId,
    private readonly router: Router
  ) {}

  async userLoggedIn() {
    if (!isPlatformBrowser(this.platformId)) {
      return Promise.resolve(false);
    }
    return Promise.resolve(!!await this.gsidSsoService.getAccessToken());
  }

  logout() {
    sessionStorage.clear();
    this.gsidSsoService.logout();
  }
}

import {
  Component,
  Input,
  Output,
  EventEmitter,
  AfterContentInit,
  OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: '[form-group-input]',
  templateUrl: 'formGroupInput.html',
  styleUrls: ['formGroupInput.component.less']
})
export class FormGroupInputComponent implements OnInit {
  @Input() e2e: any;
  @Input() type: any;
  @Input() control: any;
  @Input() label: string;
  @Input() readonly: any;
  @Input() disabled: any;
  @Input() submitted: any;
  @Input() valueClass: string;
  @Input() placeholder: string;

  //custom error messages (BIMcloud)
  @Input() errorHeader: any;
  @Input() errorMessages: any;

  //show errors
  @Input() showErrors: any;

  @Input() hint: any;

  @Output() onFocusFn: EventEmitter<any> = new EventEmitter<any>();
  @Output() onBlurFn: EventEmitter<any> = new EventEmitter<any>();

  public showError = false;
  public errors: any;

  constructor(private readonly translateService: TranslateService) {}

  ngOnInit() {
    if (!this.type) this.type = 'text';
    if (!this.e2e) this.e2e = this.label;
  }

  inputOnBlur() {
    this.showError = false;
    this.onBlurFn.emit();
  }

  inputOnFocus() {
    this.showError = true;
    this.onFocusFn.emit();
  }

  hasVisibleErrorMessage(): any {
    this.errors = [];
    let isVisible = false;

    if (!this.submitted) {
      return;
    }

    if (this.control.errors) {
      for (const index in this.control.errors) {
        if (this.errorMessages && this.errorMessages.hasOwnProperty(index)) {
          let value: string;
          if (typeof this.errorMessages[index] === 'string') {
            value = this.translateService.instant(this.errorMessages[index]);
          } else {
            value = this.translateService.instant(
              this.errorMessages[index].ErrorMessage,
              { error: this.errorMessages[index] }
            );
          }

          this.errors.push({
            key: index,
            value,
          });

          isVisible = true;
        } else {
          this.errors.push({
            key: index,
            value: this.translateService.instant(
              'FORM_ERROR_MESSAGE_' + index.toUpperCase(),
              { error: this.control.errors[index] }
            ),
          });
          isVisible = true;
        }
      }
    }

    return isVisible;
  }
}

<input type="password" [(ngModel)]="modelValue" name="password" #password="ngModel"
  [attr.placeholder]="'PASSWORD_INPUT_PASSWORD_LABEL' | translate" (ngModelChange)="checkPasswordStrengthLevel()"
  class="password-with-strength"
  [ngClass]="{'invalid': ((!password.pristine || submitted)&& (passwordStrengthLevel < 5 || !checkPasswordStrengthHasSpecialCharacter())) }"
  on-blur="inputOnBlur()" on-focus="inputOnFocus()" />

<div *ngIf="submitted && !modelValue && !showError" class="error-msg pt-3">
  {{'FORM_ERROR_MESSAGE_REQUIRED' | translate}}
</div>

<div
  *ngIf="showError && (!password.pristine && (passwordStrengthLevel < 5 || !checkPasswordStrengthHasSpecialCharacter()))">
  <div class="check-password-list-wrapper">
    <div class="header__do">{{'PASSWORD_INPUT_PASSWORD_POLICY_POPUP_DO_HEADER' |translate}}</div>
    <div class="icon-check" [ngClass]="{'removed': checkPasswordStrengthLength()}">
      {{'PASSWORD_INPUT_PASSWORD_POLICY_POPUP_6_CHARACTERS_MESSAGE' | translate}}</div>
    <div class="icon-check" [ngClass]="{'removed': checkPasswordStrengthHasLetter()}">
      {{'PASSWORD_INPUT_PASSWORD_POLICY_POPUP_ONE_LETTER_MESSAGE' | translate}}</div>
    <div class="icon-check" [ngClass]="{'removed': checkPasswordStrengthHasCapital()}">
      {{'PASSWORD_INPUT_PASSWORD_POLICY_POPUP_ONE_CAPITAL_MESSAGE' | translate}}</div>
    <div class="icon-check" [ngClass]="{'removed': checkPasswordStrengthHasNumber()}">
      {{'PASSWORD_INPUT_PASSWORD_POLICY_POPUP_ONE_NUMBER_MESSAGE' | translate}}</div>
    <div class="icon-times" [ngClass]="{'removed': checkPasswordStrengthNotEqualEmail()}">
    <div class="header__dont">{{'PASSWORD_INPUT_PASSWORD_POLICY_POPUP_DONT_HEADER' | translate}}</div>
      {{'PASSWORD_INPUT_PASSWORD_POLICY_POPUP_NOT_EMAIL_ADDRESS_MESSAGE' | translate}}</div>
    <div class="icon-times" [ngClass]="{'removed': checkPasswordStrengthHasSpecialCharacter()}">
      {{'PASSWORD_INPUT_PASSWORD_POLICY_POPUP_SPEC_CHARACTERS_MESSAGE' | translate}}</div>
  </div>
</div>

<div class="">
  <select [ngClass]="valueClass ? valueClass :'' "
    [ngClass]="{'invalid': !control.valid && (!control.pristine || submitted)}" (change)="selectOption($event)"
    [(ngModel)]="control.value">
    <option style="color: #757575;" [selected]="isSelected == placeholder" *ngIf="placeholder" disabled value="">
      {{placeholder | translate}}
    </option>
    <option *ngFor="let option of selectOptions" [attr.data-e2e]="e2e + '__option' | lowercase"
      [label]="option.value +': '+ option.label | translate" [value]="option.label">
    </option>
  </select>

  <div *ngIf="hasVisibleErrorMessage()">
    <div *ngFor="let error of errors" class="error-msg pt-3">
      {{error.value}}
    </div>
  </div>
</div>

import { Component } from '@angular/core';
import { LoaderService } from '@services/loader.service';

@Component({
    selector: '[loader]',
    templateUrl: 'loader.html',
    styleUrls: ['./loader.component.less']
})
export class LoaderComponent {

    constructor(public loaderService: LoaderService) { }

    showLoader() {
        return this.loaderService.getLoader();
    }

}

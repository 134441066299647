import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: '[form-group-select]',
  templateUrl: './form-group-select.component.html',
  styleUrls: ['./form-group-select.component.less'],
})
export class FormGroupSelectComponent implements OnInit {
  public errors: any;
  isSelected: string;
  public showError = false;

  @Input() e2e: any;
  @Input() control: any;
  @Input() submitted: any;
  @Input() errorMessages: any;
  @Input() valueClass: string;
  @Input() selectOptions: any;
  @Input() placeholder: string;
  @Output() optionSelectedEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(private readonly translateService: TranslateService) {}

  ngOnInit(): void {
    this.isSelected = '+00';
  }

  public selectOption($event: any) {
    let selectElement = $event.target;
    let optionIndex = selectElement.selectedIndex;
    let optionText = selectElement.options[optionIndex];
    let option = { label: optionText.label, value: optionText.value };

    if (this.control) {
      this.control.setValue(option.value);
      this.control.markAsDirty();
      this.control.markAsTouched();
    }
    this.optionSelectedEvent.emit(option);
  }

  hasVisibleErrorMessage(): any {
    this.errors = [];
    let isVisible = false;

    if (!this.submitted) {
      return;
    }

    if (this.control.errors) {
      for (const index in this.control.errors) {
        if (this.errorMessages && this.errorMessages.hasOwnProperty(index)) {
          let value: string;
          if (typeof this.errorMessages[index] === 'string') {
            value = this.translateService.instant(this.errorMessages[index]);
          } else {
            value = this.translateService.instant(
              this.errorMessages[index].ErrorMessage,
              { error: this.errorMessages[index] }
            );
          }

          this.errors.push({
            key: index,
            value,
          });

          isVisible = true;
        } else {
          this.errors.push({
            key: index,
            value: this.translateService.instant(
              'FORM_ERROR_MESSAGE_' + index.toUpperCase(),
              { error: this.control.errors[index] }
            ),
          });
          isVisible = true;
        }
      }
    }

    return isVisible;
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as countries from './countries-gsid.json';

@Component({
    selector: '[country-select-gsid]',
    templateUrl: 'countrySelectGsId.html',
})
export class CountrySelectGsIdComponent implements OnInit {
    public countries: any;
    @Input() submitted: any;
    @Input() errorClass: string;
    @Input() control: FormControl;
    @Input() selectedValue: any;

    ngOnInit() {
        this.countries = (countries as any).default;
        this.selectedValue = null;
    }
}

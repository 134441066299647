<footer [ngClass]="valueClass ? valueClass : ''" class="c-footer container-fluid py-9px">
  <div class="row">
    <div class="col-lg-3 col-12 pl-md-4">
      <p class="text-lg-left text-center mb-0"
        [innerHTML]="'FOOTER.GSfooterCopyrightText' | translate : { year: year }"></p>
    </div>
    <div class="link-wrapper col-lg-5 col-12">
      <a href="//redirect.graphisoft.com/?gstype=legal&gssubtype=privacy"
        title="{{'FOOTER.PRIVACY_POLICY' | translate}}" target="_blank">{{'FOOTER.PRIVACY_POLICY' | translate}}</a>
      <a href="//graphisoft.com/legal/terms-of-use/graphisoft-id" title="{{'FOOTER.TERMS_OF_USE' | translate}}"
        target="_blank">{{'FOOTER.TERMS_OF_USE' | translate}}</a>
      <a href="//redirect.graphisoft.com/?gstype=help&gssubtype=graphisoft-forward"
        title="{{'FOOTER.HELP' | translate}}" target="_blank">{{'FOOTER.HELP' |
        translate}}</a>
    </div>
    <div class="col-lg-4 col-12 pr-md-4">
      <p class="text-lg-right text-center mb-0 footer-text" [innerHTML]="'FOOTER.FOOTER_TEXT' | translate"></p>
    </div>
  </div>
</footer>

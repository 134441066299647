<app-header [valueClass]="'pb-5'"></app-header>
<div class="container pt-5">
  <div class="row">
    <div class="col-12 text-center">
      <img src="assets/icons/icon-table.svg" alt="">
    </div>
    <ng-container [ngSwitch]="item">
      <ng-container *ngSwitchCase="'1'" class="">
        <h1 class="col-8 offset-2 mb-0 pt-2-5 pb-3-125 font-48px text-center">{{'SIGN_IN_ERROR.OOPS' | translate}}</h1>
        <h4 class="col-8 offset-2 font-weight-bold">{{'SIGN_IN_ERROR.ANOTHER_COMPANY' | translate}}
        </h4>
        <p class="col-8 offset-2 mb-0 line-height-26"> {{'SIGN_IN_ERROR.ANOTHER_COMPANY_DESCRIPTION' | translate}}</p>
      </ng-container>
      <ng-container *ngSwitchCase="'2'" class="">
        <h1 class="col-8 offset-2 mb-0 pt-2-5 pb-3-125 font-48px text-center">{{'SIGN_IN_ERROR.OOPS' | translate}}</h1>
        <h4 class="col-8 offset-2 font-weight-bold">
          {{'SIGN_IN_ERROR.OTHER_INVITATION_ALREADY_ACCEPTED' | translate}}</h4>
        <p class="col-8 offset-2 mb-0 pb-2-5 line-height-26">
          {{'SIGN_IN_ERROR.OTHER_INVITATION_ALREADY_ACCEPTED_DESCRIPTION' | translate}}
        </p>
      </ng-container>
      <ng-container *ngSwitchCase="'3'" class="">
        <h1 class="col-8 offset-2 mb-0 pt-2-5 pb-3-125 font-48px text-center">{{'SIGN_IN_ERROR.OOPS' | translate}}</h1>
        <h4 class="col-8 offset-2 font-weight-bold">{{'SIGN_IN_ERROR.INVITATION_NEEDED' | translate}}</h4>
        <p class="col-8 offset-2 mb-0 pb-2-5 line-height-26"
          [innerHTML]="'SIGN_IN_ERROR.INVITATION_NEEDED_DESCRIPTION' | translate">
        </p>
      </ng-container>
      <ng-container *ngSwitchCase="'4'" class="">
        <h1 class="col-8 offset-2 mb-0 pt-2-5 pb-3-125 font-48px text-center">{{'SIGN_IN_ERROR.OOPS' | translate}}</h1>
        <h4 class="col-8 offset-2 font-weight-bold">{{'SIGN_IN_ERROR.PERMISSION_DENIED' | translate}}</h4>
        <p class="col-8 offset-2 mb-0 pb-2-5 line-height-26"> {{'SIGN_IN_ERROR.PERMISSION_DENIED_DESCRIPTION' | translate}}</p>
      </ng-container>
      <ng-container *ngSwitchCase="'5'" class="">
        <h1 class="col-8 offset-2 mb-0 pt-2-5 pb-3-125 font-48px text-center">{{'SIGN_IN_ERROR.OOPS' | translate}}</h1>
        <h4 class="col-8 offset-2 font-weight-bold">{{'SIGN_IN_ERROR.GENERIC_ERROR' | translate}}
        </h4>
        <p class="col-8 offset-2 mb-0 pb-2-5 line-height-26"> {{'SIGN_IN_ERROR.GENERIC_ERROR_DESCRIPTION' | translate}}
        </p>
      </ng-container>
    </ng-container>
  </div>
  <div class="row">
    <ng-container *ngIf="correlationId">
      <div class="col-8 offset-2 correlation-id">
        Correlation ID: {{correlationId}}
      </div>
    </ng-container>
    <ng-container *ngIf="item === '1'">
      <div class="col-8 offset-2 pt-1-25 pb-2-5 mb-0 line-height-26">
        <img class="pb-1 pr-2 line-height-26" src="assets/icons/icon_arrow.svg" alt="">
        <span class="link" (click)="signInWithDifferentAccount()">{{'SIGN_IN_ERROR.SIGN_IN_DIFFERENT_ACCOUNT' | translate}}</span>
      </div>
    </ng-container>
    <ng-container *ngIf="item !== '1'">
      <div class="col-8 offset-2">
        <div class="hr-3"></div>
      </div>
      <h4 class="col-8 offset-2 pt-2-5" [translate]="'SIGN_IN_ERROR.STILL_HAVING_PROBLEM'"></h4>
      <p class="col-8 offset-2 line-height-26" [innerHTML]="'SIGN_IN_ERROR.LOCAL_PARTNER' | translate"></p>
    </ng-container>
  </div>
</div>
<app-footer [valueClass]="'fixed-sm-footer'"></app-footer>

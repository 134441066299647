import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { Language } from '../models/language.model';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
  private loader: any;
  private languages = [
    { name: 'English', countryCode: 'en-US' },
    { name: 'Brasil (Portuguese)', countryCode: 'pt-BR' },
    { name: 'Czech', countryCode: 'cs-CZ' },
    { name: 'Finnish', countryCode: 'fi-FI' },
    { name: 'French', countryCode: 'fr-FR' },
    { name: 'Greek', countryCode: 'el-GR' },
    { name: 'Japanese', countryCode: 'ja-JP' },
    { name: 'Chinese (simplified)', countryCode: 'zh-CN' },
    { name: 'Chinese (traditional)', countryCode: 'zh-TW' },
    { name: 'Korean', countryCode: 'ko-KR' },
    { name: 'Polish', countryCode: 'pl-PL' },
    { name: 'Hungarian', countryCode: 'hu-HU' },
    { name: 'German', countryCode: 'de-DE' },
    { name: 'Italian', countryCode: 'it-IT' },
    { name: 'Russian', countryCode: 'ru-RU' },
    { name: 'Portuguese', countryCode: 'pt-PT' },
    { name: 'Spanish', countryCode: 'es-ES' },
    { name: 'Turkish', countryCode: 'tr-TR' },
    { name: 'Ukrainian', countryCode: 'uk-UA' },
  ];

  constructor(
    private translateService: TranslateService,
    private cookieService: CookieService
  ) {
    this.translateService.addLangs(this.languages.map(x => x.countryCode));
    
    var language = 'en-US';
    var defaultLanguage = 'en-US';
    if (navigator.languages && navigator.languages.length > 0) {
      var browserLanguage = this.languages.find(x => x.countryCode.startsWith(navigator.languages[0]))
      if (browserLanguage) {
        language = browserLanguage.countryCode;
      }
    }
    //this.translateService.setTranslation(defaultLanguage, (enUs as any).default);

    this.translateService.setDefaultLang(defaultLanguage);

    let selectedLanguage = this.cookieService.get('selectedLanguage');
    if (selectedLanguage) {
      this.setLanguage(selectedLanguage);
    } else {
      this.translateService.use(language);
    }
  }

  public get languageList() : Language[] {
    return this.languages;
  }

  setLanguage(isoCode?: any) {
    if (!isoCode) {
      isoCode = 'en-US';
    }

    if (isoCode.length === 2) {
      isoCode = isoCode + '-' + isoCode;
    }

    if (this.languages.find(lang=>lang.countryCode == isoCode) == null) {
      let languageCode = isoCode.split('-');
      languageCode = languageCode[0] + '-' + languageCode[0].toUpper();
      if (this.languages.indexOf(languageCode) === -1) {
        isoCode = 'en-US';
      } else {
        isoCode = languageCode;
      }
    }
    
    this.translateService.use(isoCode);
  }
}

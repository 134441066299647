import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { LoginService } from '../services/login.service';

@Injectable({
  providedIn: 'root',
})
export class UserLoggedInGuard implements CanActivate {
  constructor(
      private readonly loginService: LoginService,
      private readonly router: Router,
      @Inject(PLATFORM_ID) private platformId
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
      if (!isPlatformBrowser(this.platformId)) {
          return Promise.resolve(true);
      }

      return this.loginService.userLoggedIn().then(response => {
          if (response) {
              return Promise.resolve(response);
          } else {
            this.router.navigate(['/']);
          }
      });
  }
}

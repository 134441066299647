import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { ConfigurationService } from './configuration.service';
import { LoaderService } from './loader.service';

/*Glogális js változók*/
declare var GSIDSSO: any;

@Injectable({
  providedIn: 'root'
})
export class GsidSsoService {

  constructor(
    private configurationService: ConfigurationService,
    private readonly loaderService: LoaderService,
    private readonly router: Router,
    @Inject(PLATFORM_ID) private platformId
  ) {
    if (isPlatformBrowser(platformId)) {
      GSIDSSO.init(this.configurationService.configuration.gsIdUIUrl + "Account/SsoAuth");
    }
  }

  async getGsId() {
    const token = await this.getAccessToken();

    if (!token) {
      return null;
    }

    const payload = await GSIDSSO.getAccessTokenPayload();
    if (!payload) {
      return null;
    }
    return payload.GsId;

  }

  async getPayload() {
    const token = await this.getAccessToken();

    if (!token) {
      return null;
    }

    return await GSIDSSO.getAccessTokenPayload();    
  }

  async getAccessToken() {
    this.loaderService.showLoader();
    const token = await GSIDSSO.getAccessToken()
    this.loaderService.hideLoader();
    return token;
  }

  logout() {
    let localRouter = this.router;
    GSIDSSO.logout().then(function (response) {
      setTimeout(function () {
        localRouter.navigate(['/']);
      }, 1000);
    });
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import {
  TranslateModule,
  TranslateLoader,
  MissingTranslationHandler,
} from '@ngx-translate/core';
import { LanguageLoader } from './services/languageLoader.service';
import { ConfigurationService } from './services/configuration.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SiteModule } from './site/site.module';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ComponentsModule } from '@components/components.module';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute } from '@angular/router';
// AoT requires an exported function for factories

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth.interceptor';

export function HttpLoaderFactory(http: HttpClient) {
  return new LanguageLoader(http, '/assets/i18n/', '.json');
}

export function languageInitializer(translateLoader: TranslateLoader) {
  return () =>
    new Promise((resolve) => {
      translateLoader
        .getTranslation('en-us')
        .toPromise()
        .then(() => {
          resolve(true);
        });
      resolve(true);
    });
}

function getParamValueQueryString(paramName) {
  const url = window.location.href;
  let paramValue;
  if (url.includes('?')) {
    const httpParams = new HttpParams({ fromString: url.split('?')[1] });
    paramValue = httpParams.get(paramName);
  }
  return paramValue;
}

function loadInvitationId() {
  const invitationId = getParamValueQueryString('invitationId');
  if (invitationId) {
    sessionStorage.setItem('invitationId', invitationId);
  }
}

export function initialization(
  configurationService: ConfigurationService
): any {
  loadInvitationId();
  return () => {
    return Promise.all([
      configurationService.loadConfiguration()
    ]).then(() => {
      return true;
    });
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SiteModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ComponentsModule,
  ],
  providers: [
    ConfigurationService,
    CookieService,
    {
      provide: APP_INITIALIZER,
      useFactory: initialization,
      deps: [ConfigurationService, ActivatedRoute],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

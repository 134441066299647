import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from '@services/login.service';

@Component({
  selector: 'app-login-error',
  templateUrl: './login-error.component.html',
  styleUrls: ['./login-error.component.less'],
})
export class LoginErrorComponent implements OnInit {
  item: string;
  correlationId: string = null;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly loginService: LoginService
  ) {}

  ngOnInit(): void {
    this.item = this.activatedRoute.snapshot.queryParamMap.get('error');
    this.correlationId = this.activatedRoute.snapshot.queryParamMap.get('correlation-id');
  }

  signInWithDifferentAccount() {
    this.loginService.logout();
  }
}

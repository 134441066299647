import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'upperCaseTranslate' })

export class UpperCaseTranslatePipe implements PipeTransform {
    constructor(
        private readonly translateService: TranslateService
    ) { }

    transform(key: any, params?: any): any {
        if (!key) { return; }
        key = key.replace(/"/g, '');
        key = key.replace(/([a-z](?=[A-Z]))/g, '$1_');
        key = key.toUpperCase();
        return this.translateService.instant(key, params);
    }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: '[forward-button]',
  templateUrl: './forwardButton.component.html',
  styleUrls: ['./forwardButton.component.less'],
})
export class ForwardButtonComponent implements OnInit {
  @Input() value: string;
  @Input() valueClass: string;
  //@Input() disabledBtn: string;
  @Output() clickedEvent: EventEmitter<any> = new EventEmitter<any>();
  constructor() {}

  ngOnInit(): void {}

  buttonClick(event) {
    this.clickedEvent.emit(event);
  }
}

import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { LoaderService } from './loader.service';
import { ValidateService } from './validate.service';

declare var config: any;
@Injectable({
  providedIn: 'root',
})
export class ValidationResolverService implements Resolve<any> {
  constructor(
    private readonly loaderService: LoaderService,
    private readonly validationService: ValidateService
  ) {}

  resolve(): Promise<any> {
    this.loaderService.showLoader();
    return this.validationService.invitationValidate()
      .then((response: any) => {
        localStorage.setItem('PARTNER_COUNTRY_CODE', response.partnerCountryCode);

        this.loaderService.hideLoader();
        return {validationData: response}
      },
      (error) => {
        this.loaderService.hideLoader();
        return { validationError: error };
      });
  }
}

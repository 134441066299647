<div class="more-info">
    <div class="icon-hover">
        <ng-container *ngIf="printStyle">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.75 12.375C18.1289 12.375 17.625 12.8784 17.625 13.5C17.625 14.1211 18.1289 14.625 18.75 14.625C19.3711 14.625 19.875 14.1211 19.875 13.5C19.875 12.8784 19.3711 12.375 18.75 12.375ZM20.25 8.25V4.68187C20.25 4.08516 20.0128 3.51281 19.5909 3.09094L17.1591 0.659063C16.7372 0.237188 16.1648 0 15.5681 0H5.17875C4.38938 0 3.75 0.671719 3.75 1.5V8.25C1.67906 8.25 0 9.92906 0 12V18C0 18.4144 0.335625 18.75 0.75 18.75H3.75V23.25C3.75 23.6644 4.08563 24 4.5 24H19.5C19.9144 24 20.25 23.6644 20.25 23.25V18.75H23.25C23.6644 18.75 24 18.4144 24 18V12C24 9.92906 22.3209 8.25 20.25 8.25ZM6 2.25H15V4.5C15 4.91437 15.3356 5.25 15.75 5.25H18V8.25H6V2.25ZM18 21.75H6V18.75H18V21.75ZM21.75 16.5H2.25V12C2.25 11.1731 2.92313 10.5 3.75 10.5H20.25C21.0769 10.5 21.75 11.1731 21.75 12V16.5Z" fill="#313D6B"/>
            </svg>
    
            <span class="align-middle print-text">{{'CONTRACTUAL.PRINT_AGREEMENT' | translate}}</span>
        </ng-container>
    
        <ng-container *ngIf="!printStyle">
            <svg class="info" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 0C4.02979 0 0 4.03124 0 9C0 13.9717 4.02979 18 9 18C13.9702 18 18 13.9717 18 9C18 4.03124 13.9702 0 9 0ZM9 3.99194C9.84179 3.99194 10.5242 4.67434 10.5242 5.51613C10.5242 6.35792 9.84179 7.04032 9 7.04032C8.15821 7.04032 7.47581 6.35792 7.47581 5.51613C7.47581 4.67434 8.15821 3.99194 9 3.99194ZM11.0323 13.2097C11.0323 13.4502 10.8373 13.6452 10.5968 13.6452H7.40323C7.16273 13.6452 6.96774 13.4502 6.96774 13.2097V12.3387C6.96774 12.0982 7.16273 11.9032 7.40323 11.9032H7.83871V9.58065H7.40323C7.16273 9.58065 6.96774 9.38566 6.96774 9.14516V8.27419C6.96774 8.0337 7.16273 7.83871 7.40323 7.83871H9.72581C9.9663 7.83871 10.1613 8.0337 10.1613 8.27419V11.9032H10.5968C10.8373 11.9032 11.0323 12.0982 11.0323 12.3387V13.2097Z" fill="#C4C4C4"/>
            </svg>
        </ng-container>
    </div>

    <div class="info-window" [ngClass]="{ 'print-info': printStyle, 'left-open': !rightOpen, 'right-open': rightOpen }">
        <div [ngClass]="{ 'print-flex': printStyle }">
            <div *ngIf="printStyle">
                <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.7584 0.397804C22.6375 0.131234 22.4 0 22.1625 0C21.9417 0 21.7167 0.11483 21.5792 0.348591C20.2876 2.55907 17.9751 3.93703 15.3335 3.93703H12.0003C7.58372 3.93703 4.00047 7.46395 4.00047 11.8111C4.00047 12.5411 4.1088 13.2424 4.29629 13.9108C1.20887 16.556 0.0755602 19.6359 0.0547273 19.6934C-0.124435 20.206 0.150558 20.7678 0.67138 20.9442C1.2547 21.141 1.78385 20.7883 1.94218 20.3372C1.95885 20.2921 2.81716 17.9791 5.12128 15.8219C6.68791 18.4138 9.64617 19.9722 12.8503 19.64C19.3959 19.1725 24 13.3982 24 6.32796C24 4.26922 23.55 2.13666 22.7584 0.397804ZM12.6419 17.6838C9.05452 18.057 7.20039 15.4487 6.71707 14.5465C8.69203 13.1973 11.3836 12.1392 15.0044 12.1392C15.5544 12.1392 16.0044 11.6963 16.0044 11.1549C16.0044 10.6136 15.5544 10.1707 15.0044 10.1707C11.2003 10.1707 8.29204 11.2164 6.08792 12.6067C6.05042 12.3442 6.00459 12.0818 6.00459 11.8111C6.00459 8.55484 8.69619 5.90555 12.0044 5.90555H15.3377C17.7501 5.90555 19.9876 5.00331 21.6709 3.40799C21.8875 4.35944 22.0042 5.356 22.0042 6.32796C22 12.5206 18.0918 17.2942 12.6419 17.6838Z" fill="#0FBC00"/>
                </svg>
            </div>
            <div>
                {{info}}
            </div>
        </div>
    </div>
</div>
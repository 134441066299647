import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
    constructor(
      private http: HttpClient,
    ) { }

  private config: any;

  public get configuration(): any {
    return this.config;
  }

    /**
     * Gets the translations from the server
     * @param lang
     * @returns {any}
     */
    public loadConfiguration(): any {
      return this.http.get("GetUiConfiguration").toPromise().then(
        (response: any) => {
          this.config = response;
          return true;
        });
    }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-validation',
  template: '',
})
export class ValidationComponent implements OnInit {
  companyPayLoad: any;

  constructor(
    private readonly _route: ActivatedRoute,
    private readonly router: Router
  ) {}

  ngOnInit() {
    let invitationId = this._route.snapshot.queryParamMap.get('invitationId');
    if (invitationId) {
      sessionStorage.setItem('invitationId', invitationId);
    }

    this._route.data.subscribe((response: any) => {
      var validationData = response.data.validationData;
      if (validationData != null) {
        sessionStorage.setItem('invitationId', validationData.invitationId);
        if (validationData.contractInformation == null) {
          this.router.navigate(['contractual/information']);
        } else {
          this.router.navigate(['details']);
        }
      } else {
        let errorHeaders = response.data.validationError.headers;
        let error = response.data.validationError.error;
        let correlationId = errorHeaders.get('x-correlation-id');

        
        switch (error.ErrorCode) {
          case 1:
            this.router.navigateByUrl('/sign-in-error?error=1&correlation-id=' + correlationId);
            break;
          case 2:
            this.router.navigateByUrl('/sign-in-error?error=2&correlation-id=' + correlationId);
            break;
          case 3:
            this.router.navigateByUrl('/sign-in-error?error=3&correlation-id=' + correlationId);
            break;
          case 4:
            this.router.navigateByUrl('/sign-in-error?error=4&correlation-id=' + correlationId);
            break;
          default:
            console.error(error);
            this.router.navigateByUrl('/sign-in-error?error=5&correlation-id=' + correlationId);
        }
      }
    });
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CountryService } from '@services/country.service';
import { Countries } from 'src/app/models/countries.model';

@Component({
  selector: '[country-select]',
  templateUrl: 'countrySelect.html',
})
export class CountrySelectComponent implements OnInit {
  public countries: Countries[];
  @Input() submitted: any;
  @Input() errorClass: string;
  @Input() control: FormControl;
  @Input() selectedValue: any;

  constructor(
    private readonly countryService: CountryService
  ) { }

  ngOnInit() {
    this.countryService.getCountriesJson().then(
      async (response: Countries[]) => {
        this.countries = response;
      },
      (error: any) => {
        throw error;
      }
    );

    this.selectedValue = null;
  }
}

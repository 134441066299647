import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '@pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { FooterComponent } from './footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LoaderComponent } from './loader/loader.component';
import { HeaderComponent } from './header/header.component';
import { PasswordInputComponent } from './passwordInput/passwordInput.component';
import { CountrySelectComponent } from './countrySelect/countrySelect.component';
import { ForwardButtonComponent } from './forwardButton/forwardButton.component';
import { FormGroupInputComponent } from './formGroupInput/formGroupInput.component';
import { FormGroupSelectComponent } from './form-group-select/form-group-select.component';
import { InformationBoxComponent } from './information-box/information-box.component';
import { CountrySelectGsIdComponent } from './countrySelectGsId/countrySelectGsId.component';
@NgModule({
  declarations: [
    FooterComponent,
    LoaderComponent,
    HeaderComponent,
    FormGroupInputComponent,
    PasswordInputComponent,
    CountrySelectComponent,
    CountrySelectGsIdComponent,
    ForwardButtonComponent,
    FormGroupSelectComponent,
    InformationBoxComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    PipesModule,
    FooterComponent,
    LoaderComponent,
    HeaderComponent,
    ForwardButtonComponent,
    PasswordInputComponent,
    CountrySelectComponent,
    CountrySelectGsIdComponent,
    FormGroupInputComponent,
    FormGroupSelectComponent,
    InformationBoxComponent
  ]
})
export class ComponentsModule { }

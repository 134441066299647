import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-information-box',
  templateUrl: './information-box.component.html',
  styleUrls: ['./information-box.component.less']
})
export class InformationBoxComponent implements OnInit {

  @Input() info: string = null;
  @Input() printStyle = false;
  @Input() rightOpen = false;

  constructor() { }

  ngOnInit(): void {
  }

}

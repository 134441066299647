<div class="form-group label-inside" [ngClass]="valueClass ? valueClass : ''">
  <input type="{{type}}" [formControl]="control" [attr.placeholder]="placeholder | translate"
    [attr.data-e2e]="e2e | lowercase" on-blur="inputOnBlur()" on-focus="inputOnFocus()" [readonly]="readonly"
    [ngClass]="{'valid': control.valid, 'invalid': !control.valid && (!control.pristine || submitted), 'disabled': disabled }"
    data-hj-whitelist />

  <div *ngIf="hasVisibleErrorMessage()">
    <div *ngFor="let error of errors" class="error-msg pt-3">
      {{error.value}}
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { from } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
/*Glogális js változók*/
declare var GSIDSSO: any;
declare var config: any;

@Injectable({
  providedIn: 'root',
})
export class JwtHttp {
  constructor(private http: HttpClient) {}

  get(url: string, options?: any): any {
    let gridssoPromise = new Promise<any>((resolve, reject) => {
      this.getTokenHeader(options).then((response: any) => {
        this.http
          .get(url, response)
          .toPromise()
          .then(
            (response: any) => {
              response = this.errorMsgParser(response);
              resolve(response);
            },
            (error: any) => {
              error = this.errorMsgParser(error);
              reject(error);
            }
          );
      });
    });
    //return Observable.fromPromise(gridssoPromise);
    return from(new Promise((resolve) => resolve(gridssoPromise)));
  }

  post(url: string, request: any, options?: any): any {
    let gridssoPromise = new Promise<any>((resolve, reject) => {
      this.getTokenHeader(options).then((response: any) => {
        this.http
          .post(url, request, response)
          .toPromise()
          .then(
            (response: any) => {
              response = this.errorMsgParser(response);
              resolve(response);
            },
            (error: any) => {
              error = this.errorMsgParser(error);
              reject(error);
            }
          );
      });
    });
    return from(new Promise((resolve) => resolve(gridssoPromise)));
  }

  put(url: string, request: any, options?: any): any {
    let gridssoPromise = new Promise<any>((resolve, reject) => {
      this.getTokenHeader(options).then((response: any) => {
        this.http
          .put(url, request, response)
          .toPromise()
          .then(
            (response: any) => {
              response = this.errorMsgParser(response);
              resolve(response);
            },
            (error: any) => {
              error = this.errorMsgParser(error);
              reject(error);
            }
          );
      });
    });
    return from(new Promise((resolve) => resolve(gridssoPromise)));
  }

  getTokenHeader(options?: any): any {
    return new Promise<any>((resolve, reject) => {
      GSIDSSO.getAccessToken().then(
        (response: any) => {
          if (options == null) {
            options = {};
          }
          if (options.headers == null) {
            options.headers = new HttpHeaders({
              'Content-Type': 'application/json',
            });
          }

          if (config.antiForgeryToken) {
            options.headers = options.headers.append(
              'X-XSRF-Token',
              config.antiForgeryToken
            );
          }

          if (response) {
            options.headers = options.headers.append('Access-Token', response);
          }
          resolve(options);
        },
        (error: any) => {}
      );
    });
  }

  errorMsgParser(response: any) {
    // if (!response._body || response._body.length === 0) {
    //     return response;
    // }

    // const resp = JSON.parse(response._body);
    // if (!resp.ErrorMessage) {
    //     return response;
    // }
    // const upperCaseTranslatePipe = new UpperCaseTranslatePipe(this.translateService);
    // resp.ErrorMessage = upperCaseTranslatePipe.transform('GSID_ERROR_MSG_' + resp.ErrorMessage);
    // response._body = JSON.stringify(resp);
    return response;
  }
}

import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { JwtHttp } from './jwt.interceptor';

@Injectable({
  providedIn: 'root',
})
export class ValidateService {
  constructor(
    private readonly configurationService: ConfigurationService,
    private readonly jwtHttp: JwtHttp
  ) {}

  async invitationValidate() {

    let invitationId = sessionStorage.getItem('invitationId') ?? null;

    let request = {
      invitationId: invitationId,
    };

    return this.jwtHttp
      .post(this.configurationService.configuration.applicationUrl + 'api/invitation/validate', request).toPromise();
  }
}

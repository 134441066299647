import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { Countries } from 'src/app/models/countries.model';
import { CountryResource } from '../models/country-resources.model';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  constructor(
    private readonly configurationService: ConfigurationService,
    private readonly http: HttpClient
  ) { }

  public getCountriesJson() {
    return this.http.get(this.configurationService.configuration.applicationUrl + 'api/resources/country')
      .toPromise();
  }

  public getCountryResourcesJson(countryCode: string) {
    return this.http.get(this.configurationService.configuration.applicationUrl + 'api/resources/country-resources?countryCode=' + countryCode)
      .toPromise();
  }

  public getCountryNameByCode(CountryCode: string) : Promise<string>
  {
    return this.getCountriesJson().then(
      async (response: Countries[]) => {
        let countries = response;

        let country = countries.find(
          (e) => e.CountryCode == CountryCode
        );

        return country ? country.CountryName : null;
      },
      (error: any) => {
        throw error;
      }
    );
  }

  public getCountryResourceUrlByCode(CountryCode: string): Promise<string>
  {
    return this.getCountryResourcesJson(CountryCode).then(
      async (countryResource: CountryResource) => {
        return countryResource ? countryResource.url : 'https://graphisoft.com/forward';
      },
      (error: any) => {
        throw error;
      }
    );
  }
}

import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { GsidSsoService } from '@services/gsid-sso.service';
import { CookieService } from 'ngx-cookie-service';
import { Language } from '../../models/language.model';
import { LanguageService } from '../../services/language.service';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less'],
})
export class HeaderComponent implements OnInit {
  @Input() valueClass: string;
  userLoggedIn: boolean = false;
  public languages: Language[];
  public selectedLanguage;
  public loggedInUserName;
  constructor(
    private elRef: ElementRef,
    private readonly loginService: LoginService,
    private renderer: Renderer2,
    private languageService: LanguageService,
    private readonly gsidSsoService: GsidSsoService,
    private cookieService: CookieService) { }

  ngOnInit(): void {
    this.loginService.userLoggedIn().then(response => {
        this.userLoggedIn = response;
    });
    
    this.languages = this.languageService.languageList;
    this.gsidSsoService.getPayload().then(x => { this.loggedInUserName = x?.EmailAddress })
    this.selectedLanguage = this.cookieService.get('selectedLanguage');

    document.addEventListener('mouseup', function (e) {
      var targetElement = event.target || event.srcElement;
      var classList = (targetElement as HTMLElement).classList;
      if (classList != null && classList.contains('dropdown-controller')) {
        return;
      }
      var containers = Array.from(document.getElementsByClassName('dropdown-menu'));
      containers.forEach(container => {
        var htmlContainer = container as HTMLElement;
        if (htmlContainer != null && !htmlContainer.contains(e.target as HTMLElement)) {
          htmlContainer.classList.remove('show');
        }
      })
    });
  }

  public selectLanguage(language: Language) {
    this.cookieService.set('selectedLanguage', language.countryCode, 365, '/');
    this.selectedLanguage = language.countryCode;
    this.languageService.setLanguage(this.selectedLanguage);

    var element = this.elRef.nativeElement.querySelector('.languageSelector');
    this.removeClass(element, 'show');

    location.reload();
  }

  public showLanguage() {
    var element = this.elRef.nativeElement.querySelector('.languageSelector');
    this.toggleClass(element, 'show');

    var element = this.elRef.nativeElement.querySelector('.userSelector');
    this.removeClass(element, 'show');

  }

  public showUser() {
    var element = this.elRef.nativeElement.querySelector('.userSelector');
    this.toggleClass(element, 'show');

    var element = this.elRef.nativeElement.querySelector('.languageSelector');
    this.removeClass(element, 'show');
  }

  private toggleClass(element, className) {
    const hasClass = element.classList.contains(className);
    if (hasClass) {
      this.renderer.removeClass(element, className);
    } else {
      this.renderer.addClass(element, className);
    }
  }

  private removeClass(element, className) {
    const hasClass = element.classList.contains(className);
    if (hasClass) {
      this.renderer.removeClass(element, className);
    }
  }

  public logoutUser() {
    this.loginService.logout();
  }
}

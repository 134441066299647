import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigurationService } from '@services/configuration.service';
import { LoginService } from '@services/login.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit {
  public title = 'Forward Login';

  constructor(
    private readonly configurationService: ConfigurationService,
    private readonly loginService: LoginService,
    private readonly router: Router
  ) {}

  ngOnInit() {
    this.loginService.userLoggedIn().then(response => {
      if (response) {
        this.router.navigate([ "/validation" ]);
      } else {
        const loginUrl = `${this.configurationService.configuration.ssoLoginUrl}&ReturnUrl=${encodeURIComponent(window.location.origin + this.router.url)}`;
        window.location.href = loginUrl;
      }
    });
  }
}

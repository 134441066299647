import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { LoginSkipGuard } from './guards/login-skip.guard';
import { UserLoggedInGuard } from './guards/user-logged-in.guard';
import { ValidationResolverService } from './services/validation-resolver.service';
import { LoginErrorComponent } from './site/profile/login-error/login-error.component';
import { ValidationComponent } from './site/validation/validation.component';

const routes: Routes = [
  {
    path: '',
    component: AppComponent
  },
  {
    path: 'sign-in',
    component: AppComponent
  },
  {
    path: 'sign-in-error',
    component: LoginErrorComponent
  },
  {
    path: 'contractual/information',
    canActivate: [UserLoggedInGuard],
    loadChildren: () =>
      import('./site/contractual/contractual.module').then(
        (mod) => mod.ContractualModule
      ),
  },
  {
    path: 'details',
    canActivate: [UserLoggedInGuard],
    loadChildren: () =>
      import('./site/profile/profile.module').then((mod) => mod.ProfileModule),
  },
  {
    path: 'validation',
    component: ValidationComponent,
    canActivate: [UserLoggedInGuard],
    resolve: {
      data: ValidationResolverService,
    }
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
